import React, { useState } from 'react';
import { printAttendeeRows } from './ReviewerTableComponent'
import { printSurveyColumns } from './SurveyTableComponent'

class TableComponent extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
          selectedItem: 'Attendees',
        }
        this.orderReviewers = this.orderReviewers.bind(this)
    }

    orderReviewers (reviewers) {
      if (this.state.selectedItem === "Reviewers") {
        return reviewers.sort((a, b) =>
          a.name < b.name ? -1 :
          b.name < a.name ? 1 :
          0
        )
      }
      return reviewers.sort((a, b) => 
        a.attendee.name < b.attendee.name ? -1 :
        b.attendee.name < a.attendee.name ? 1 :
        0
      )
    }

    render() {
        const reviewers = this.orderReviewers(this.props.reviewers)
        const filtered_reviewers = reviewers.filter((r) => {
          if (this.state.selectedItem === "Attendees") {
              return !!r.self
          }
          return !r.self
        })
        const surveys = this.props.surveys

        return (
            // Create container
            <div
                key={"responses_grid_container"}
                className='grid_container'
                style={{
                    display: 'grid',
                    padding: '10px',
                    placeItems: 'stretch',
                    margin: '10px',
                    fontSize: '12px',
                    textAlign: 'center',
                    position: 'absolute'
                }}
            >
                {/* Show 'Total' in table */}
                <div
                    key={"responses_total_tag"}
                    className='total_tag'
                    style={{
                        gridArea: '1/2/3/2',
                        border: '1px solid #dfdfdf',
                        textAlign: 'center'
                    }}><b>Total</b></div>

                {/* Show 'Self' tag */}
                <div
                    key={"responses_self_tag"}
                    className='self_tag'
                    style={{
                        gridArea: '3/2/4/3',
                        border: '1px solid #dfdfdf'
                    }}><b>Self</b></div>

                {/* Show 'Reviewer' tag */}
                <div
                    key={"responses_reviewer_tag"}
                    className="reviewer_tag"
                    style={{
                        gridArea: '4 / 2 / 5 / 3',
                        border: '1px solid #dfdfdf'
                    }}
                ><b>Reviewer</b></div>

                <div
                    key={"responses_empty_row_1"}
                    className="empty_row"
                    style={{
                        gridRow: '5 / 6',
                        gridColumn: '1 / span 2'
                    }}
                >
                    <br />
                </div>

                {/* Show select */}
                <div
                    key={"attendee_tag"}
                    className="attendee_tag"
                    style={{
                        gridArea: '6 / 2 / 7 / 3',
                        border: '1px solid #dfdfdf'
                    }}
                ><b>
                  <select name="reviewer" id="reviewerType" onChange={(e) => this.setState({ selectedItem: e.target.value})}>
                    <option value="Attendees">Attendees</option>
                    <option value="Reviewers">Reviewers</option>
                  </select>
                </b></div>

                {/* Print attendee - reviewer pairs */}
                {
                    printAttendeeRows(
                        filtered_reviewers,
                        this.state.selectedItem
                    )
                }

                {/* Print survey data */}
                {
                    printSurveyColumns(
                        reviewers,
                        filtered_reviewers,
                        surveys,
                        this.state.selectedItem
                    )
                }

            </div>
        )
    }
}

export default TableComponent;