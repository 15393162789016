import React, { useState, useEffect } from 'react'
import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { calculatePercentageDifference, CompetenceData } from '..'
import { head, sortBy } from 'lodash'

type Props = {
    surveyOneId?: string
    surveyTwoId?: string
    inputData?: CompetenceData[],
}

type LeastImprovedCompetence = {
    name: string,
    averageIncrease: number
}

const useStyles = makeStyles((theme) => ({
    container: {
        border: '2px solid gray',
        margin: '0.5em',
        padding: '0.25em',
        paddingBottom: '0.25em',
        paddingLeft: '1em',
        paddingRight: '1em',
    },
    header: {
        fontWeight: 'bold',
    },
    negativePercent: {
        color: 'red'
    },
    positivePercent: {
        color: 'green'
    }
}))

const LeastImprovedCompetencies: React.FC<Props> = ({ surveyOneId, surveyTwoId, inputData }) => {
    const classes = useStyles()

    const [loading, setLoading] = useState(true)
    const [competencies, setCompetencies] = useState<LeastImprovedCompetence[]>([])

    useEffect(() => {
        void (async() => {
            if (surveyOneId && surveyTwoId) {
                setLoading(true)
                const surveyOneData = inputData?.filter(c=>c.surveyId===surveyOneId)
                const surveyTwoData = inputData?.filter(c=>c.surveyId===surveyTwoId) 
                const leastImprovedCompetencies:LeastImprovedCompetence[] = []
                surveyOneData?.forEach(c1=>{
                    const c2 = head(surveyTwoData?.filter(c=>c1.statement === c.statement))
                    if(c1 && c2){
                        const diff = calculatePercentageDifference(c1, c2)
                        leastImprovedCompetencies.push({name:c1.statement, averageIncrease:diff})
                    }
            
                })
                setCompetencies(sortBy(leastImprovedCompetencies, 'averageIncrease').filter(c=>c.averageIncrease<10).slice(0, 3))
                setLoading(!inputData)
            }
        })()
    },[surveyOneId, surveyTwoId, inputData])

    const surveysSelected = (surveyOneId && surveyTwoId)

    return (
        <Grid container className={classes.container}>
            <Grid item>
                <p className={classes.header}>Least improved competencies &#40;Assessment score showing &#60;10% Improvement&#41;:</p>

                {!surveysSelected && (
                    <p>Please select two surveys to compare</p>
                )} 

                {loading && (
                    <p>Loading...</p>
                )}

                {competencies.length === 0 && !loading && surveysSelected && (
                    <p>None</p>
                )} 

                {competencies.length > 0 && !loading && surveysSelected && (
                    <>
                        {competencies.map((competence) => (
                            <p key={competence.name}>
                                {competence.name}:
                                <span
                                    className={competence.averageIncrease > 0 ? classes.positivePercent : classes.negativePercent}
                                >
                                    {Math.round(competence.averageIncrease)}%
                                </span>
                            </p>
                        ))}
                    </>
                )}
            </Grid>
        </Grid>
    )

}

export default LeastImprovedCompetencies