import { api } from '.';
import { Programme } from '../types'
import { query_params } from '../helpers/api'

export const getNextLastList = (filter:any) => 
  api.get(`/programme/next_last_list/${query_params(filter)}`).then((response : any) => response.data.data);

export const getAllProgrammes = (filter:any = {}) =>
  api.get(`/programme/${query_params(filter)}`).then(response => response.data.data);

export const getShowProgramme = (programmeId: string):Promise<Programme> =>
  api.get(`/programme/${programmeId}`).then(response => response.data);

export const saveProgramme = (programme: Partial<Programme>) =>
  api.put(`/programme`, programme).then(response => response.data);

export const createNew = (programme: any) =>
  api.post(`/programme`, programme).then(response => response.data);

export const updateProgrammeField = (programmeId: string, fieldName: string, newValue?: string) => {
  return getShowProgramme(programmeId)
  .then((programme:any) => {
    programme[fieldName] = newValue;
    return saveProgramme(programme);
  });
}

export const deleteProgrammeById = (programmeId: string) =>
  api.delete(`/programme/${programmeId}`).then(response => response.data)

export const getProgrammeSurveyUrls = (programmeId: string, survey: string) => 
  api.get(`/programme/${programmeId}/survey/${survey}/urls`).then(response => response.data)


type CloneProgrammeArgs = {
  programme: Programme,
  cloneAttendees: boolean,
  cloneReviewers: boolean,
} 
export const cloneProgramme = (args: CloneProgrammeArgs) =>
  api.post(`/programme/${args.programme.id}/clone`, args).then(response => response.data);
