import { api } from '.';
import { query_params } from '../helpers/api'

/** @Todo Add pagination params */
export const findAttendeesByProgrammeId = (programme: string) => {
  return api.get(`/attendee/${query_params({programme})}`).then((response: any) => response.data.data);
};

export const getAttendeeShow = (attendeeId: string) =>
  api.get(`/attendee/${attendeeId}`).then((response: any) => response.data);

export const deleteAttendeeById = (attendeeId: string) =>
  api.delete(`/attendee/${attendeeId}`).then((response: any) => attendeeId);

export const createNew = (attendee: any) =>
  api.post(`/attendee`, attendee).then(response => response.data);

export const saveAttendee = (attendee: any) =>
  api.put(`/attendee`, attendee).then(response => response.data);

export const updateAttendeeField = (attendeeId: string, fieldName: string, newValue: string) => {
  return getAttendeeShow(attendeeId)
    .then(attendee => {
      attendee[fieldName] = newValue;
      return saveAttendee(attendee);
    });
}

