import React, { useEffect, useState, useRef } from 'react'
import { useParams } from 'react-router-dom'
import { getShowProgramme } from 'src/api/programme'
import { Programme, Survey } from 'src/types'
import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import LowestRatedCompentencies from './LowestRatedCompetencies'
import LeastImprovedCompetencies from './LeastImprovedCompetencies'
import { getSurveyListByProgramme } from 'src/api/survey'
import SurveySelector from './SurveySelector/SurveySelector'
import AverageAssessmentByCompetencyChart from './AverageAssessmentByCompetencyChart'
import SurveyCompetencyScoreAvgRangeTable from './SurveyCompetencyScoreAvgRangeTable'
import AveragePerformanceImprovement from './AveragePerformanceImprovement'
import NoTimeOrOpportunityChart from './NoTimeOrOpportunityChart'
import { Checkbox } from '@material-ui/core'
import { getSurveyCompetencyScoreAvgRange } from 'src/api/dashboard'
import ReactToPrint from 'react-to-print';

const useStyles = makeStyles(() => ({
	container: {
		width: '100%',
		height: '100%',
		justifyContent: 'space-between'
	},
	column: {
		padding: '0.5em',
		height: '100%'
	}
}))
export type CompetenceData = {
    minLevel: number,
    maxLevel: number,
    avgLevel: number,
    statement: string,
    surveyId: string,
	surveyName: string,
}
export type DashboardData = {
	attendees: CompetenceData[]
	reviewers: CompetenceData[],
	all: CompetenceData[],
}

export const calculatePercentageDifference = (firstSurvey: CompetenceData, lastSurvey: CompetenceData) => {
        const difference = lastSurvey.avgLevel - firstSurvey.avgLevel
        const percentageDiff = (difference / firstSurvey.avgLevel) * 100
        return Math.round(percentageDiff * 100) / 100
    }

const Dashboard = () => {
	const printRef = useRef(null);
	const { programme_id } = useParams()
	const classes = useStyles()
 	
	const [programme, setProgramme] = useState<Programme>()
	const [surveys, setSurveys] = useState<Survey[]>([])
	const [fullyCompletedOnly, setFullyCompletedOnly] = useState(true)
	const [data, setData] = useState<DashboardData>()
	const [referenceSurvey, setReferenceSurvey] = useState<Survey>()
	const [comparisonSurvey, setComparisonSurvey] = useState<Survey>()
	useEffect(() => {
		void (async () => {
			const foundProgramme = await getShowProgramme(programme_id)
			setProgramme(foundProgramme)
			const foundSurveys = await getSurveyListByProgramme(programme_id)
			setSurveys(foundSurveys)
			setComparisonSurvey(foundSurveys.length>0 ? foundSurveys[0] : undefined)
			setReferenceSurvey(foundSurveys.length>1 ? foundSurveys[1] : undefined)
		})()
	},[programme_id])
	const formatData = (data:any):CompetenceData[] => {
		const formatted =  data.flatMap((survey: any) => (
                survey.statements.flatMap((d: any) => ({
                    ...d,
					statement:d.statement.name,
                    surveyId: survey.survey.id,
					surveyName: survey.survey.name,
                }))
            ))
		console.log('formatted', formatted)
		return formatted
	}
	useEffect(() => {
        void (async () => {
            const attendeeData = await getSurveyCompetencyScoreAvgRange({ programmeId:programme_id, table: 'attendee', fullyCompletedOnly })
			const reviewerData = await getSurveyCompetencyScoreAvgRange({ programmeId:programme_id, table: 'reviewer', fullyCompletedOnly })
			const allData = await getSurveyCompetencyScoreAvgRange({ programmeId:programme_id, table: 'all', fullyCompletedOnly })
            
            setData({
				attendees: formatData(attendeeData),
				reviewers: formatData(reviewerData),
				all: formatData(allData),
			})
        })()
    },[programme_id, fullyCompletedOnly])   
	if (!programme) {
		return (
			<p>Loading...</p>
		)
	}
	return (
	<>	
	<div>
	<ReactToPrint
        trigger={() => <button>Print (Save as PDF)</button>}
        content={() => printRef.current}
		pageStyle={".divider { break-after: always; }"}
      />
</div>
		<Grid container className={classes.container}  style={{maxWidth:1200}} ref={printRef}>
			<style>
				{`@media all {
  .page-break {
    display: none;
  }
}

@media print {
  html, body {
    height: initial !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
  }
}

@media print {
  .page-break {
    margin-top: 1rem;
    display: block;
    page-break-before: auto;
  }
}

@media print {
					@page {
						size: 300mm 550mm;
					}
				}`}
  			</style>
			<Grid container xs={12} item> 
				<Grid container xs={6} direction="column" item>
						<h1>{programme.title}</h1>
						<div onClick={(e)=>{setFullyCompletedOnly(!fullyCompletedOnly)}}>
							<Checkbox
								checked={fullyCompletedOnly}
								color={'primary'}
								disabled={!programme}
								
							/>
							<span>Only include mostly completed surveys</span>
						</div>
				</Grid>
				<Grid container xs={6} direction="row" item>
						<Grid container xs={6} direction="column" item> 
							<p>Survey to compare against:</p>
							<SurveySelector
								surveys={surveys.filter((s) => !referenceSurvey || s.programme_date_offset_days < referenceSurvey.programme_date_offset_days )}
								surveySelectedCallback={(id) => setComparisonSurvey(surveys.filter((s)=> s.id===id)[0])}
								selectedSurvey={comparisonSurvey}
							/>
						</Grid>
						<Grid container xs={6} direction="column" item> 
							<p>Reference Survey:</p>
							<SurveySelector
								surveys={surveys.filter((s) => !comparisonSurvey || s.programme_date_offset_days > comparisonSurvey.programme_date_offset_days )}
								surveySelectedCallback={(id) => setReferenceSurvey(surveys.filter((s)=> s.id===id)[0])}
								selectedSurvey={referenceSurvey}
							/>
						</Grid>
					</Grid>
			</Grid>


			<Grid container>
				<Grid container direction="column" xs={6} className={classes.column} item>
					<AverageAssessmentByCompetencyChart inputData={data?.all} />
				</Grid>
				
				<Grid container direction="column" xs={6} className={classes.column} item>
					<Grid item>
						<AveragePerformanceImprovement 
							surveyOne={comparisonSurvey}
							surveyTwo={referenceSurvey}
							inputDataAttendees={data?.attendees}
							inputDataReviewers={data?.reviewers}
						/>
						
						<LowestRatedCompentencies 
							programmeId={programme_id}
							survey={surveys.find((survey) => survey.id === comparisonSurvey?.id)}
							inputData={data?.all}
						/>
						<LowestRatedCompentencies 
							programmeId={programme_id}
							survey={surveys.find((survey) => survey.id === referenceSurvey?.id)}
							inputData={data?.all}
						/>
						<LeastImprovedCompetencies
							surveyOneId={comparisonSurvey?.id}
							surveyTwoId={referenceSurvey?.id}
							inputData={data?.all}
						/>
						
					</Grid>
				</Grid>
			</Grid>
				<Grid container xs={12}>
					
				<Grid className={classes.column} item xs={6} >
					<NoTimeOrOpportunityChart programmeId={programme_id} filter={'attendee'} fullyCompletedOnly={fullyCompletedOnly} />
				</Grid>
				<Grid className={classes.column} item xs={6} >
					<NoTimeOrOpportunityChart programmeId={programme_id} filter={'reviewer'} fullyCompletedOnly={fullyCompletedOnly} />
				</Grid>
			</Grid>
			<Grid container xs={12} item>
				<SurveyCompetencyScoreAvgRangeTable inputData={data?.attendees} type="attendee" key={'att-table'}/>
				<SurveyCompetencyScoreAvgRangeTable inputData={data?.reviewers} type="reviewer" key={'rev-table'}/>
			</Grid>


		</Grid>
</>

	)
}

export default Dashboard


