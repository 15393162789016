import React, { useState, useEffect } from 'react'
import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Survey } from 'src/types'
import { sortBy } from 'lodash'
import { CompetenceData } from '..'

type Props = {
    programmeId: string
    survey?: Survey,
    inputData?: CompetenceData[],
}

type LowRatingCompetency = {
    name: string,
    averageRating: number
}

const useStyles = makeStyles(() => ({
    container: {
        border: '2px solid gray',
        margin: '0.5em',
        padding: '0.25em',
        paddingBottom: '0.25em',
        paddingLeft: '1em',
        paddingRight: '1em',
    },
    header: {
        fontWeight: 'bold',
    }
}))


const LowestRatedCompentencies:React.FC<Props> = ({ programmeId, survey, inputData }) => {
    const classes = useStyles()

    const [loading, setLoading] = useState(true)
    const [competencies, setCompetencies] = useState<LowRatingCompetency[]>([])

    useEffect(() => {
        void (async () => {
            if (survey) {
                setLoading(true)
                const surveyId = survey.id
                const lowestRatedCompentencies = 
                    sortBy(inputData?.filter(c=>c.surveyId == surveyId), 'avgLevel')
                        .map(c=>({name:c.statement, averageRating:Math.round(c.avgLevel)}))
                        
                setCompetencies(lowestRatedCompentencies.filter(c=>c.averageRating<45).slice(0,3))
                setLoading(!inputData)
            }
        })()
    },[programmeId, survey, inputData])

  

    return (
        <Grid container className={classes.container}>
            <Grid item>
                {!survey && (
                    <>
                        <p className={classes.header}>Lowest rated competencies &#40;scoring &#60;45&#41;:</p>
                        <p>Please select a survey</p>
                    </>
                )} 
        {(loading && competencies.length !== 0) &&
            <>Loading...</>
         }
    
                {survey && (
                    <>
                        <p className={classes.header}>{survey.name}: lowest rated competencies &#40;scoring &#60;45&#41;:</p>
                        {loading && (
                            <p>Loading...</p>
                        )}
                        {competencies.length === 0 && !loading && (
                            <p>None</p>
                        )}
                        {competencies.length > 0 && !loading && (
                            <>
                                {competencies.map((competence) => (
                                    <p key={competence.name}>{competence.name}: {competence.averageRating}</p>
                                ))}
                            </>
                        )}
                    </>
                )}
            </Grid>
        </Grid>
    )
}

export default LowestRatedCompentencies