import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';

import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://0d799ee20bd8948980a9cc3e64fcf8cd@o4506160791093248.ingest.sentry.io/4506282264035328",
  environment: window.location.host,
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", `${window.location.protocol}//${window.location.host}/api`],
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});
Sentry.captureMessage('New session started');
ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
