import { Grid, makeStyles } from '@material-ui/core'
import { sortBy, sumBy } from 'lodash'
import React, { useState, useEffect } from 'react'
import { Legend, Pie, PieChart, ResponsiveContainer, Tooltip, Cell } from 'recharts'
import { getNoTimeOpportunitySums } from 'src/api/dashboard'

type Props = {
    programmeId: string,
    filter: 'attendee' | 'reviewer' | 'all'
    fullyCompletedOnly:boolean
}

type PieChartData = {
    name: string,
    value: number,
}

const useStyles = makeStyles(() => ({
    container: {
        border: '2px solid gray',
        margin: '0.5em',
        padding: '0.25em',
        display: 'flex',
        flexDirection: 'column',
        displayContent: 'center',
        alignContent: 'center',
    },
    chartContainer: {
        width: '100%',
        height: '20em',
    },
    header: {
        fontWeight: 'bold',
        textAlign: 'center',
        fontSize: 21,
    },
    noData: {
        marginLeft: '1em',
    }
}))

const colors = [
    '#26377B',
    '#3e7287',
    '#8f45b5',
    '#400e59',
    '#0e594d',
  ];


const NoTimeOrOpportunityChart:React.FC<Props> = ({ programmeId, filter, fullyCompletedOnly }) => {
    const classes = useStyles() 
    const [data, setData] = useState<PieChartData[]>([])
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        void (async () => {
            setLoading(true)
            const foundData = await getNoTimeOpportunitySums({ programmeId, table:filter, fullyCompletedOnly })
            const sum = sumBy(foundData, 'value')
            const toPercent = foundData.map((d:any) => ({...d, value:(Math.round(d.value/sum*100))}))
            setData(sortBy(toPercent, 'name'))
            setLoading(false)
        })()    
    },[programmeId, filter, fullyCompletedOnly])

    return (
        <Grid container className={classes.container}>
            <p className={classes.header}>Percentage of {filter === 'attendee' ? 'attendees' : 'reviewers'} where no assessment given because of no time and/or no opportunity</p>
            {loading && (
                <p>Loading...</p>
            )}
            {!loading && (
                <div className={classes.chartContainer}>
                    {data.length === 0 && (
                        <p className={classes.noData}>No data could be found</p>
                    )}
                    {data.length > 0 && (
                        <ResponsiveContainer>
                            <PieChart>
                                <Pie data={data} dataKey="value" nameKey="name" cx="50%" cy="50%" startAngle={-270} endAngle={-630}>
                                    {data.map((d, index) => (
                                        <Cell
                                            key={`cell-${index}`}
                                            fill={d.name==='Answered' ? 'lightgray' : colors[index % colors.length]}
                                        />
                                    ))} 
                                </Pie>
                                <Legend/>
                                <Tooltip/>
                            </PieChart>
                        </ResponsiveContainer>
                    )}
                </div>
            )}
        </Grid>
    )

}

export default NoTimeOrOpportunityChart