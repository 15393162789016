import React, { useContext } from 'react';
import { Switch, Route, Redirect, useRouteMatch } from 'react-router-dom';
import Page from '../Layout/Page';
import { NavBar } from './NavBar';
import { Metrics, Responses, ProgrammeDetails, Dashboard } from './Tabs';
import { SurveyUrls } from './Tabs/ProgrammeDetails/SurveyUrls';
import { UserContext, isPlatformAdmin } from '../user/context';

export type ProgrammeDetailsTab = 'Programme Details' | 'Responses' | 'Metrics';
export const tabs: Record<string, ProgrammeDetailsTab> = {
  PROGRAMME_DETAILS: 'Programme Details',
  RESPONSES: 'Responses',
  METRICS: 'Metrics'
};

export const ProgrammeDetailsPage = () => {
  const userContext = useContext(UserContext)

  const { path } = useRouteMatch()
  return (
    <Page navBar={<NavBar/>}>
      <Switch>
        <Route path={`${path}/details`} component={ProgrammeDetails}/>
        <Route path={`${path}/responses`} component={Responses}/>
        {isPlatformAdmin(userContext.user) && <Route path={`${path}/metrics`} component={Metrics}/>}
        <Route path={`${path}/survey/urls`} component={SurveyUrls}/>
         {isPlatformAdmin(userContext.user) && <Route path={`${path}/dashboard`} component={Dashboard} />}
        <Redirect to={`${path}/details`} />
      </Switch>
    </Page>
  )
};
