import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { groupBy, sortBy } from 'lodash'
import { calculatePercentageDifference, CompetenceData } from '..';

const useStyles = makeStyles(() => ({
    container: {
        marginLeft: '1em',
        width: '100%'
    },
    table: {
        border: '1px solid white',
        borderSpacing: 0,
        width: '100%'
    },
    row: {
        '&:nth-child(even)': {
            backgroundColor: '#e2e0e6'
        },
        '&:nth-child(odd)': {
            backgroundColor: '#c2c5d0'
        },
    },
    cell: {
        alignContent: 'center',
        borderBottom: '1px solid white',
        borderRight: '1px solid white',
        wordBreak: 'break-word',
        padding: '0.2em',
        textAlign: 'center'
    },
    bold: {
        fontWeight: 'bold'
    },
    tableHeader: {
        backgroundColor: '#1b2865',
        fontWeight: 'bold',
        color: 'white',
        textAlign: 'center',
        width: '100%',
        fontSize: 32,
    },
    overallPerformance: {
        fontSize: 18,
        textAlign: 'center'
    },
    positivePerformance: {
        color: 'green'
    },
    negativePerformance: {
        color: 'red'
    }
}));

type Props = {
    type: 'attendee' | 'reviewer' | 'all'
    inputData?: CompetenceData[],
}

const SurveyCompetencyScoreAvgRangeTable:React.FC<Props> = ({ inputData, type }) => {
    const classes = useStyles();
    const [data, setData] = useState<CompetenceData[]>()
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        void (async () => {
            setLoading(true)
            
            setData(sortBy(inputData, 'survey','competence'))
            setLoading(false)
        })()
    },[inputData, type])   

    
    return (
        <Grid className={classes.container}>
            <div className={classes.tableHeader}>
                {type === 'attendee' ? 'Attendee' : 'Reviewer'}
            </div>
            <table className={classes.table}>
                <tbody>
                    <tr className={classes.row}>
                        <th className={classes.cell} key={'th1'} >Competencies by average and range of responses</th>
                        {Object.keys(groupBy(data, 'surveyName')).map((survey) => (
                            <th className={classes.cell} key={survey}>{survey}</th>
                        ))}
                        <th className={classes.cell} key={'thop'}>
                            <p className={classes.overallPerformance}>Overall Performance</p>
                        </th>
                    </tr>
                    {Object.values(sortBy(groupBy(data, 'statement'), 'statement')).map((statements, index) => (
                        <tr className={classes.row} key={'c'+index}>
                            <td className={classes.cell} key={'td'+index}>{statements[0].statement}</td>
                            {statements.map((statement, index2) => (
                                <>
                                    <td className={classes.cell} key={'td2'+index+'-'+index2}>
                                        <p className={classes.bold}>{Math.round(statement.avgLevel)}</p>
                                        <p>{Math.round(statement.minLevel)}-{Math.round(statement.maxLevel)}</p>
                                    </td>        
                                </>
                            ))}
                            <td className={classes.cell} key={'cell1'}>
                                <p className={classes.overallPerformance}>
                                    <span className={calculatePercentageDifference(statements[0], statements[statements.length-1]) > 0 
                                        ? classes.positivePerformance 
                                        : classes.negativePerformance}
                                    >
                                        {Math.round(calculatePercentageDifference(statements[0], statements[statements.length-1]))}%
                                    </span>
                                </p>
                            </td>
                        </tr>
                    ))}
                </tbody>
           </table>
        </Grid>
    )
}

export default SurveyCompetencyScoreAvgRangeTable